<template>
  <v-card>
    <v-card-title>取引先(新規)</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div v-for="msg in apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <template>
        <v-spacer></v-spacer>
        <v-form class="ma-6">
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="cust_abbr">取引先略称<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="cust_abbr"
                v-model="$v.CustomerForm.cust_abbr.$model"
                outlined
                dense
                :error-messages="custAbbrErrors"
                placeholder="取引先略称"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="cust_name">取引先名<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="cust_name"
                v-model="$v.CustomerForm.cust_name.$model"
                outlined
                dense
                :error-messages="nameErrors"
                placeholder="取引先名（日本語、中国語等）"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="cust_name">取引先名（ENG）<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="cust_name_eng"
                v-model="$v.CustomerForm.cust_name_eng.$model"
                outlined
                dense
                :error-messages="nameEngErrors"
                placeholder="取引先名(英語)"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="country_name">国・地域ID<span style="color:red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="CustomerForm.country_id"
                outlined
                dense
                hide-details="auto"
                :items="countryList"
                :error-messages="countryIdErrors"
                placeholder="国・地域ID"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="postcode">郵便番号</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="postcode"
                v-model="CustomerForm.postcode"
                outlined
                dense
                placeholder="郵便番号"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="address1">住所1</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="address1"
                v-model="CustomerForm.address1"
                outlined
                dense
                placeholder="住所1（東京都台東区上野1-2-3/广东省深圳市福田区深南大道2409号）"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="address1_eng">Address1</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="address1_eng"
                v-model="CustomerForm.address1_eng"
                outlined
                dense
                placeholder="Address1"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="address2">住所2</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="address2"
                v-model="CustomerForm.address2"
                outlined
                dense
                placeholder="住所２（上野ビル301室/福田商业大楼902室)"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="address2_eng">Address2</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="address2_eng"
                v-model="CustomerForm.address2_eng"
                outlined
                dense
                placeholder="Input the address2 in English"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="corp_rep_title">法人格</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="corp_rep_title"
                v-model="CustomerForm.corp_rep_title"
                outlined
                dense
                placeholder="代表取締役/法人代表"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="corp_rep_name">法人代表者名</label>
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                id="corp_rep_name"
                v-model="CustomerForm.corp_rep_name"
                outlined
                dense
                placeholder="小泉 太郎/王 大刚"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="manager_sei">連絡人姓名</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="manager_sei"
                v-model="CustomerForm.manager_sei"
                outlined
                dense
                placeholder="連絡人姓（佐藤・劉）"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="manager_mei"
                v-model="CustomerForm.manager_mei"
                outlined
                dense
                placeholder="連絡人名（一郎・大剛）"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="manager_sei">連絡人姓名（ENG）</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="manager_firstname"
                v-model="CustomerForm.manager_firstname"
                outlined
                dense
                placeholder="連絡人姓(ENG)"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="manager_lastname"
                v-model="CustomerForm.manager_lastname"
                outlined
                dense
                placeholder="連絡人名(ENG)"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="mobile">携帯電話番号</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="mobile"
                v-model="CustomerForm.mobile"
                outlined
                dense
                placeholder="携帯電話番号"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="tel">固定電話番号</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="tel"
                v-model="CustomerForm.tel"
                outlined
                dense
                placeholder="固定電話番号"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="tel">税番号</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="tel"
                v-model="$v.CustomerForm.cust_tax_no.$model"
                outlined
                dense
                placeholder="税番号"
                :error-messages="taxNoErrors"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="email">E-mail<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="email"
                v-model="CustomerForm.email"
                outlined
                :error-messages="emailErrors"
                dense
                placeholder="E-mail"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="fax">FAX</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="fax"
                v-model="CustomerForm.fax"
                outlined
                dense
                placeholder="FAX"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="website">Website</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="website"
                v-model="CustomerForm.website"
                outlined
                dense
                placeholder="Website"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="wechat">WECHAT</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="wechat"
                v-model="CustomerForm.wechat"
                outlined
                dense
                placeholder="WECHAT"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="line">LINE</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                id="line"
                v-model="CustomerForm.line"
                outlined
                dense
                placeholder="LINE"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label for="line">状態</label>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="CustomerForm.cust_stat"
                placeholder="状態"
                :items="custStatuses"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              offset-md="5"
              cols="12"
            >
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="submit(CustomerForm)"
              >
                Submit
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </template>
    </template>
  </v-card>
</template>

<script>

import {
  helpers,
} from '@vuelidate/validators'
import {
  required,
  maxLength,
  email,
} from 'vuelidate/lib/validators'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'

const allowedChar = helpers.regex(/^[0-9A-Z-]*$/)

export default {
  components: {
    // CountrySelect,
    // RegionSelect,
  },
  data: () => ({
    submitStatus: false,
    changeFlag: false,
    CustomerForm: {
      country_id: '',

      // country_name: '',

      // country_code: '',
      cust_abbr: '',
      cust_name: '',
      cust_name_eng: '',

      postcode: '',
      address1: '',
      address1_eng: '',
      address2: '',
      address2_eng: '',

      corp_rep_title: '',
      corp_rep_name: '',
      manager_sei: '',
      manager_mei: '',
      manager_firstname: '',
      manager_lastname: '',
      mobile: '',
      email: '',
      cust_tax_no: '',
      tel: '',
      fax: '',
      website: '',
      line: '',
      wechat: '',
      cust_stat: 0,

      // supplier: 0,
    },

    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    countryList: [],
  }),

  validations: {
    CustomerForm: {
      cust_abbr: {
        required,
        allowedChar,
        maxLength: maxLength(20),
      },
      cust_name: {
        required,
        maxLength: maxLength(100),
      },
      cust_name_eng: {
        required,
        maxLength: maxLength(100),
      },

      // country_id: {
      //   required,
      // },
      country_id: {
        required,
      },
      cust_tax_no: {
        allowedChar,
        maxLength: maxLength(20),
      },

      email: {
        required,
        email,
      },

      // mobile: {
      //   required,
      //   numeric,
      // },
    },
  },

  computed: {
    ...mapState('countryStore', ['countryMaster', 'countryIds', 'countries', 'countryCode']),
    ...mapState('customerStore', ['custStatuses']),

    // ...mapState('areaStore', ['areaMaster', 'areaIds']),
    country_id() {
      if (this.countryMaster) {
        const countryKey = this.CustomerForm.country_id

        return this.countryMaster[countryKey]
      }

      return ''
    },

    nameErrors() {
      const errors = []

      if (!this.$v.CustomerForm.cust_name.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.cust_name.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.cust_name.maxLength && errors.push('100文字以内にしてください。')

      return errors
    },

    nameEngErrors() {
      const errors = []

      if (!this.$v.CustomerForm.cust_name_eng.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.cust_name_eng.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.cust_name_eng.maxLength && errors.push('100文字以内にしてください。')

      return errors
    },

    countryIdErrors() {
      const errors = []

      if (!this.$v.CustomerForm.country_id.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.country_id.required && errors.push('必須項目')

      return errors
    },

    emailErrors() {
      const errors = []

      if (!this.$v.CustomerForm.email.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.email.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.email.email && errors.push('正しくメールアドレスを入力してください。')

      return errors
    },

    taxNoErrors() {
      const errors = []
      if (!this.$v.CustomerForm.cust_abbr.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.cust_tax_no.maxLength && errors.push('20文字以内にしてください。')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.cust_tax_no.allowedChar && errors.push('0-9A-Z-を入力してください。')

      return errors
    },
    custAbbrErrors() {
      const errors = []
      if (!this.$v.CustomerForm.cust_abbr.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.cust_abbr.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.cust_abbr.maxLength && errors.push('20文字以内にしてください。')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.CustomerForm.cust_abbr.allowedChar && errors.push('0-9A-Z-を入力してください。')

      return errors
    },

  },

  created() {
    this.loadData()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCountries(),
        this.loadCustomerStatus(),

        // this.loadCountryIds(),

        // this.loadAreaIds(),
      ]).then(() => {
        this.countryList = this.countries.map(c => {
          const obj = {
          }
          obj.text = `${c.country_name} (${c.country_code})`
          obj.value = c.country_id

          return obj
        })
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
        console.log('countryMaster', this.countryMaster)
      })
    },
    jumpToListPage() {
      this.$router.push({
        path: '/customer-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    submit(CustomerForm) {
      console.log('Submit:', CustomerForm)
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.createCustomer(CustomerForm)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
        window.scrollTo(0, 0)
      }
    },
    ...mapActions('countryStore', ['loadCountryIds', 'loadCountries']),

    // ...mapActions('areaStore', ['loadAreaIds']),
    ...mapActions('customerStore', ['createCustomer', 'loadCustomerStatus']),
  },
}
</script>
